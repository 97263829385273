
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "GoodsConsigneesTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const loading = ref(false);
    const consignees = ref({
      loading: false,
      options: [],
      list: [],
    });
    const consigneesType = ref([
      {
        value: "E",
        label: "Entrada - check in",
      },
      {
        value: "S",
        label: "Salida - check out",
      },
      {
        value: "ES",
        label: "Entrada/Salida",
      },
    ]);
    const headers = ref([
      { label: "iconsignne", prop: "consignee_id" },
      { label: "itype", prop: "type" },
      { label: "isection", prop: "sections" },
      { label: "iinitialBL", prop: "initial_bl" },
      { label: "ifinalBL", prop: "final_bl" },
      {
        label: "iinitialDeparture",
        prop: "initial_departure",
      },
      { label: "ifinalDeparture", prop: "final_departure" },
      { label: "ibaseSection", prop: "base_section" },
      {
        label: "isectionIdentifier",
        prop: "section_identificator",
      },
    ]);
    const headerTable = ref(headers.value);

    const getConsignees = () => {
      ApiService.get("/api/resource/entity").then(({ data }) => {
        consignees.value.list = data;
        consignees.value.options = data;
      });
    };
    const selectConsignees = (query) => {
      if (query !== "") {
        consignees.value.loading = true;
        setTimeout(() => {
          consignees.value.loading = false;
          consignees.value.options = consignees.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        consignees.value.options = [];
      }
    };

    onMounted(() => getConsignees());

    return {
      loading,
      headers,
      headerTable,
      consignees,
      consigneesType,
      selectConsignees,
    };
  },
});
