
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ShipMooringTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const loading = ref(false);
    const requirements = ref({
      loading: false,
      options: [],
      list: [],
    });
    const purposes = ref({
      loading: false,
      options: [],
      list: [],
    });
    const docks = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "ismrequirement", prop: "requirement_id" },
      { label: "ismpurpose", prop: "purpose_id" },
      { label: "iport", prop: "dock_id" },
      { label: "icheckin", prop: "check_in" },
      { label: "icheckout", prop: "check_out" },
    ]);
    const headerTable = ref(headers.value);

    const getRequirements = () => {
      ApiService.get("/api/resource/shipMooringRequirement").then(
        ({ data }) => {
          requirements.value.list = data;
          requirements.value.options = data;
        }
      );
    };
    const selectRequirements = (query) => {
      if (query !== "") {
        requirements.value.loading = true;
        setTimeout(() => {
          requirements.value.loading = false;
          requirements.value.options = requirements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        requirements.value.options = [];
      }
    };

    const getPurposes = () => {
      ApiService.get("/api/resource/shipMooringPurpose").then(({ data }) => {
        purposes.value.list = data;
        purposes.value.options = data;
      });
    };
    const selectPurposes = (query) => {
      if (query !== "") {
        purposes.value.loading = true;
        setTimeout(() => {
          purposes.value.loading = false;
          purposes.value.options = purposes.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        purposes.value.options = [];
      }
    };

    const getDocks = () => {
      ApiService.get("/api/resource/dock").then(({ data }) => {
        docks.value.list = data;
        docks.value.options = data;
      });
    };
    const selectDocks = (query) => {
      if (query !== "") {
        docks.value.loading = true;
        setTimeout(() => {
          docks.value.loading = false;
          docks.value.options = docks.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        docks.value.options = [];
      }
    };

    onMounted(() => {
      getRequirements();
      getPurposes();
      getDocks();
    });

    return {
      loading,
      headers,
      headerTable,
      requirements,
      purposes,
      docks,
      selectRequirements,
      selectPurposes,
      selectDocks,
    };
  },
});
