
import {computed, onMounted, ref} from "vue";
import ApiService from "@/core/services/ApiService";
import {useStore} from "vuex";

export default {
  name: "ParticipantsTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();
    const loading = ref(false);
    const bookingTableData = computed(() => store.getters.Bookings);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    });
    const participantType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "tibookings", prop: "booking_id" },
      { label: "ientity", prop: "entity_id" },
      { label: "iparticipantType", prop: "participant_type_id" },
      { label: "ientitydata", prop: "entity_data" },
    ]);
    const headerTable = ref(headers.value);

    const getElements = () => {
      ApiService.get("/api/resource/entity").then(({ data }) => {
        elements.value.list = data;
        elements.value.options = data;
      });
    };
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        setTimeout(() => {
          elements.value.loading = false;
          elements.value.options = elements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        elements.value.options = [];
      }
    };

    const getParticipantType = () => {
      ApiService.get("/api/resource/participantsType").then(({ data }) => {
        participantType.value.list = data;
        participantType.value.options = data;
      });
    };
    const selectParticipantType = (query) => {
      if (query !== "") {
        participantType.value.loading = true;
        setTimeout(() => {
          participantType.value.loading = false;
          participantType.value.options = participantType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        participantType.value.options = [];
      }
    };

    onMounted(() => {
      getElements();
      getParticipantType();
    });

    return {
      loading,
      bookingTableData,
      elements,
      participantType,
      headers,
      headerTable,
      selectElements,
      selectParticipantType,
    };
  },
};
