
import { defineComponent, onUnmounted, ref } from "vue";
import MaritimeFileTabs from "@/components/maritime-file/MaritimeFileTabs.vue";
import OverviewTab from "@/components/maritime-file/tabs/OverviewTab.vue";
import Booking from "@/components/maritime-file/tabs/Booking.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "MaritimeFileForm",
  components: {
    Booking,
    MaritimeFileTabs,
    OverviewTab,
  },

  setup() {
    const store = useStore();
    const tab = ref("overview");

    const selectedTab = (value) => {
      tab.value = value;
    };

    onUnmounted(() => {
      store.commit("resetModule");
    });

    return {
      tab,
      selectedTab,
    };
  },
});
