
import { defineComponent, ref, computed, onMounted, onUnmounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import MaritimeFileTypeSelect from "@/components/catalogs-select/MaritimeFileTypeSelect.vue";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import PortsSelect from "@/components/catalogs-select/PortsSelect.vue";
import ShipsSelect from "@/components/catalogs-select/ShipsSelect.vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
import ContactSelect from "@/components/catalogs-select/ContactSelect.vue";
import MaritimeServicesSelect from "@/components/catalogs-select/MaritimeServicesSelect.vue";
import OperationTypesSelect from "@/components/catalogs-select/OperationTypesSelect.vue";
import MaritimeVoyageTE from "@/components/maritime-file/tables-editable/MaritimeVoyageTE.vue";
import GoodsConsigneesTE from "@/components/maritime-file/tables-editable/GoodsConsigneesTE.vue";
import ShipMooringTE from "@/components/maritime-file/tables-editable/ShipMooringTE.vue";
import MooringOperationTE from "@/components/maritime-file/tables-editable/MooringOperationTE.vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

interface FormMaritimeFile {
  id?: number;
  code: string;
  file_type_id: string;
  office_id: string;
  port_id: string;
  vessel_id: string;
  ship_consignee_id: string;
  customer_id: string;
  contact_id: string;
  check_in_id: string;
  check_out_id: string;
  operation_id: string;
  prefix: string;
  calls: string;
  import: string;
  export: string;
}

export default defineComponent({
  name: "OverviewTab",
  components: {
    MaritimeVoyageTE,
    GoodsConsigneesTE,
    ShipMooringTE,
    MooringOperationTE,
    OperationTypesSelect,
    MaritimeServicesSelect,
    ContactSelect,
    EntitySelect,
    Form,
    Field,
    ErrorMessage,
    MaritimeFileTypeSelect,
    OfficeSelect,
    PortsSelect,
    ShipsSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const tripTableData = computed(() => store.getters.MaritimeTrips);
    const consigneeTableData = computed(() => store.getters.GoodsConsignees);
    const mooringTableData = computed(() => store.getters.ShipMoorings);
    const operationTableData = computed(() => store.getters.MooringOperations);
    const mtEditTable = ref(false);
    const form = ref<FormMaritimeFile>({
      code: "",
      file_type_id: "",
      office_id: "",
      port_id: "",
      vessel_id: "",
      ship_consignee_id: "",
      customer_id: "",
      contact_id: "",
      check_in_id: "",
      check_out_id: "",
      operation_id: "",
      prefix: "",
      calls: "",
      import: "",
      export: "",
    });

    const isCodeRequired = (value) => {
      if (!value) {
        return t("rcode");
      }
      return true;
    };

    const createMTElement = () => {
      if (tripTableData.value.length > 0) {
        let invalidTable = false;
        tripTableData.value.forEach((item, index) => {
          if (index === tripTableData.value.length - 1) {
            invalidTable =
              item.code === "" ||
              item.shipowner_id === "" ||
              item.trip_type === "";
          }
        });
        if (!invalidTable) {
          addMTLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rmteditabletable"),
            type: "error",
          });
        }
      } else {
        addMTLine();
      }
    };

    const addMTLine = () => {
      store.commit("setMaritimeTrip", {
        maritime_file_id: "",
        code: "",
        shipowner_id: "",
        trip_type: "",
        remarks: "",
      });
    };

    const removeMTItem = (index) => {
      store.commit("removeMaritimeTrip", index);
    };

    const createGCElement = () => {
      if (consigneeTableData.value.length > 0) {
        let invalidTable = false;
        consigneeTableData.value.forEach((item, index) => {
          if (index === consigneeTableData.value.length - 1) {
            invalidTable =
              item.consignee_id === "" ||
              item.type === "" ||
              item.base_section === "";
          }
        });
        if (!invalidTable) {
          addGCLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rgceditabletable"),
            type: "error",
          });
        }
      } else {
        addGCLine();
      }
    };

    const addGCLine = () => {
      store.commit("setGoodsConsignees", {
        maritime_file_id: "",
        consignee_id: "",
        type: "",
        sections: "",
        initial_bl: "",
        final_bl: "",
        initial_departure: "",
        final_departure: "",
        base_section: "",
        section_identificator: "",
      });
    };

    const removeGCItem = (index) => {
      store.commit("removeGoodsConsignees", index);
    };

    const createSMElement = () => {
      if (mooringTableData.value.length > 0) {
        let invalidTable = false;
        mooringTableData.value.forEach((item, index) => {
          if (index === mooringTableData.value.length - 1) {
            invalidTable =
              item.requirement_id === "" ||
              item.purpose_id === "" ||
              item.dock_id === "" ||
              item.check_in === "" ||
              item.check_out === "";
          }
        });
        if (!invalidTable) {
          addSMLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rsmeditabletable"),
            type: "error",
          });
        }
      } else {
        addSMLine();
      }
    };

    const addSMLine = () => {
      store.commit("setShipMooring", {
        id: mooringTableData.value.length + 1,
        requirement_id: "",
        purpose_id: "",
        dock_id: "",
        check_in: "",
        check_out: "",
      });
    };

    const removeSMItem = (index) => {
      store.commit("removeShipMooring", index);
    };

    const createMOElement = () => {
      if (operationTableData.value.length > 0) {
        let invalidTable = false;
        operationTableData.value.forEach((item, index) => {
          if (index === operationTableData.value.length - 1) {
            invalidTable =
              item.ship_mooring_id === "" ||
              item.operation_type_id === "" ||
              item.station_id === "" ||
              item.goods_type_id === "";
          }
        });
        if (!invalidTable) {
          addMOLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rmoeditabletable"),
            type: "error",
          });
        }
      } else {
        addMOLine();
      }
    };

    const addMOLine = () => {
      store.commit("setMooringOperation", {
        ship_mooring_id: "",
        operation_type_id: "",
        station_id: "",
        goods_type_id: "",
      });
    };

    const removeMOItem = (index) => {
      store.commit("removeMooringOperation", index);
    };

    const onSubmit = (value) => {
      if (
        !tripTableData.value.length ||
        !consigneeTableData.value.length ||
        !mooringTableData.value.length ||
        !operationTableData.value
      ) {
        ElNotification({
          title: "Error",
          message: t("rmaritimefiletables"),
          type: "error",
        });
        return;
      }
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        let payload = { ...form.value };
        payload["trips"] = tripTableData.value;
        payload["good_consignees"] = consigneeTableData.value;

        let finalMooring = [] as any;
        mooringTableData.value.forEach((x) => {
          const operations = operationTableData.value.filter(
            (o) => o.ship_mooring_id === x.id
          );
          if (maritimeFileId.value === undefined) {
            delete x["id"];
          }
          operations.forEach((y) => delete y["ship_mooring_id"]);
          const mooring = { ...x };
          mooring.mooring_operations = operations;
          finalMooring.push(mooring);
        });
        payload["ship_moorings"] = finalMooring;

        if (maritimeFileId.value === undefined) {
          ApiService.post("/api/maritimefiles/", payload)
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              form.value = data;
              store.commit("setMaritimeFileId", form.value.id);
              store.commit("setMaritimeFile", form.value);
              router.push({
                name: "maritimefileEdit",
                params: { id: data.id },
              });
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        } else {
          delete payload["id"];
          ApiService.put(`/api/maritimefiles/${maritimeFileId.value}`, payload)
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              form.value = data;
              store.commit("setMaritimeFileId", form.value.id);
              store.commit("setMaritimeFile", form.value);
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        }
      }
    };

    onMounted(() => {
      const paramsRoute = route.params;
      if (Object.keys(paramsRoute).length > 0 && paramsRoute.id !== null) {
        store.commit("setMaritimeFileId", paramsRoute.id);
        ApiService.get("/api/maritimefiles/" + paramsRoute.id).then(
          ({ data }) => {
            store.commit("setMaritimeFile", data);
            form.value.id = data.id;
            form.value.code = data.code;
            form.value.file_type_id = data.type.id;
            form.value.office_id = data.office.id;
            form.value.port_id = data.port.id;
            form.value.vessel_id = data.vessel.id;
            form.value.ship_consignee_id = data.ship_consignee_id;
            form.value.customer_id = data.customer_id;
            form.value.contact_id = data.contact_id;
            form.value.check_in_id = data.check_in_id;
            form.value.check_out_id = data.check_out_id;
            form.value.operation_id = data.operation_id;
            form.value.prefix = data.prefix;
            form.value.calls = data.calls;
            form.value.import = data.import;
            form.value.export = data.export;

            data.maritime_voyages.forEach((x) => {
              store.commit("setMaritimeTrip", {
                id: x.id,
                maritime_file_id: x.maritime_file_id,
                code: x.code,
                shipowner_id: x.shipowner_id,
                trip_type: x.trip_type,
                remarks: x.remarks,
              });
            });

            data.goods_consignees.forEach((x) => {
              store.commit("setGoodsConsignees", {
                id: x.id,
                maritime_file_id: x.maritime_file_id,
                consignee_id: x.consignee_id,
                type: x.type,
                sections: x.sections,
                initial_bl: x.initial_bl,
                final_bl: x.final_bl,
                initial_departure: x.initial_departure,
                final_departure: x.final_departure,
                base_section: x.base_section,
                section_identificator: x.section_identificator,
              });
            });

            data.ship_moorings.forEach((x) => {
              store.commit("setShipMooring", {
                id: x.id,
                maritime_file_id: x.maritime_file_id,
                requirement_id: x.requirement_id,
                purpose_id: x.purpose_id,
                dock_id: x.dock_id,
                check_in: x.check_in,
                check_out: x.check_out,
              });
            });
          }
        );
      }
    });

    return {
      form,
      tripTableData,
      consigneeTableData,
      mooringTableData,
      operationTableData,
      mtEditTable,
      submitButton,
      createMTElement,
      removeMTItem,
      createGCElement,
      removeGCItem,
      createSMElement,
      removeSMItem,
      createMOElement,
      removeMOItem,
      onSubmit,
      isCodeRequired,
    };
  },
});
