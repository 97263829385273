
import { defineComponent, ref, onMounted, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "MooringOperationTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();
    const loading = ref(false);
    const mooringTableData = computed(() => store.getters.ShipMoorings);
    const operationsType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const stations = ref({
      loading: false,
      options: [],
      list: [],
    });
    const goods = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      {
        label: "tmoorings",
        prop: "ship_mooring_id",
      },
      {
        label: "ismoperationType",
        prop: "operation_type_id",
      },
      { label: "istation", prop: "station_id" },
      { label: "igoodsType", prop: "goods_type_id" },
    ]);
    const headerTable = ref(headers.value);

    const getOperationsType = () => {
      ApiService.get("/api/resource/mooringOperationType").then(({ data }) => {
        operationsType.value.list = data;
        operationsType.value.options = data;
      });
    };
    const selectOperationsType = (query) => {
      if (query !== "") {
        operationsType.value.loading = true;
        setTimeout(() => {
          operationsType.value.loading = false;
          operationsType.value.options = operationsType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        operationsType.value.options = [];
      }
    };

    const getStations = () => {
      ApiService.get("/api/resource/mooringOperationType").then(({ data }) => {
        stations.value.list = data;
        stations.value.options = data;
      });
    };
    const selectStations = (query) => {
      if (query !== "") {
        stations.value.loading = true;
        setTimeout(() => {
          stations.value.loading = false;
          stations.value.options = stations.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        stations.value.options = [];
      }
    };

    const getGoods = () => {
      ApiService.get("/api/resource/goodsType").then(({ data }) => {
        goods.value.list = data;
        goods.value.options = data;
      });
    };
    const selectGoods = (query) => {
      if (query !== "") {
        goods.value.loading = true;
        setTimeout(() => {
          goods.value.loading = false;
          goods.value.options = goods.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        goods.value.options = [];
      }
    };

    onMounted(() => {
      getOperationsType();
      getStations();
      getGoods();
    });

    return {
      loading,
      headers,
      headerTable,
      operationsType,
      stations,
      goods,
      mooringTableData,
      selectOperationsType,
      selectStations,
      selectGoods,
    };
  },
});
