
import { computed, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "BookingRoutesTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();
    const loading = ref(false);
    const bookingTableData = computed(() => store.getters.Bookings);
    const routeType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const locations = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "tibookings", prop: "booking_id" },
      { label: "routes_type", prop: "route_type_id" },
      { label: "location_type", prop: "location_id" },
      { label: "iorder", prop: "order" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const getRouteType = () => {
      ApiService.get("/api/resource/routeType").then(({ data }) => {
        routeType.value.list = data;
        routeType.value.options = data;
      });
    };
    const selectRouteType = (query) => {
      if (query !== "") {
        routeType.value.loading = true;
        setTimeout(() => {
          routeType.value.loading = false;
          routeType.value.options = routeType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        routeType.value.options = [];
      }
    };

    const getLocation = () => {
      ApiService.get("/api/resource/location").then(({ data }) => {
        locations.value.list = data;
        locations.value.options = data;
      });
    };
    const selectLocation = (query) => {
      if (query !== "") {
        locations.value.loading = true;
        setTimeout(() => {
          locations.value.loading = false;
          locations.value.options = locations.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        locations.value.options = [];
      }
    };

    onMounted(() => {
      getRouteType();
      getLocation();
    });

    return {
      loading,
      bookingTableData,
      routeType,
      locations,
      headers,
      headerTable,
      selectRouteType,
      selectLocation,
    };
  },
};
