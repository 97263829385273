
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "MaritimeVoyageTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const loading = ref(false);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    });
    const tripType = ref([
      { value: "I", label: "Import" },
      { value: "E", label: "Export" },
    ]);
    const headers = ref([
      { label: "icode", prop: "code" },
      {
        label: "ishipowner",
        prop: "shipowner_id",
      },
      {
        label: "itripType",
        prop: "trip_type",
      },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const getElements = () => {
      ApiService.get("/api/resource/entity").then(({ data }) => {
        elements.value.list = data;
        elements.value.options = data;
      });
    };
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        setTimeout(() => {
          elements.value.loading = false;
          elements.value.options = elements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        elements.value.options = [];
      }
    };

    onMounted(() => getElements());

    return {
      loading,
      tripType,
      headers,
      headerTable,
      elements,
      selectElements,
    };
  },
});
