
import { defineComponent, ref, computed } from "vue";
import { Form } from "vee-validate";
import TransshipmentTE from "@/components/maritime-file/tables-editable/TransshipmentTE.vue";
import BookingRoutesTE from "@/components/maritime-file/tables-editable/BookingRoutesTE.vue";
import ParticipantsTE from "@/components/maritime-file/tables-editable/ParticipantsTE.vue";
import BillsOfLadingTE from "@/components/maritime-file/tables-editable/BillsOfLadingTE.vue";
import ShiploadTE from "@/components/maritime-file/tables-editable/ShiploadTE.vue";
import GoodsFeaturesTE from "@/components/maritime-file/tables-editable/GoodsFeaturesTE.vue";
import BookingsTE from "@/components/maritime-file/tables-editable/BookingsTE.vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Booking",
  components: {
    BookingsTE,
    GoodsFeaturesTE,
    ShiploadTE,
    BillsOfLadingTE,
    ParticipantsTE,
    BookingRoutesTE,
    TransshipmentTE,
    Form,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const bookingTableData = computed(() => store.getters.Bookings);
    const transshipmentTableData = computed(() => store.getters.Transshipments);
    const routeTableData = computed(() => store.getters.BookingRoutes);
    const participantTableData = computed(() => store.getters.Participants);
    const billOfLandingTableData = computed(() => store.getters.BillsOfLanding);
    const shiploadTableData = computed(() => store.getters.Shiploads);
    const goodTableData = computed(() => store.getters.Goods);

    const createBKElement = () => {
      if (bookingTableData.value.length > 0) {
        let invalidTable = false;
        bookingTableData.value.forEach((item, index) => {
          if (index === bookingTableData.value.length - 1) {
            invalidTable =
              item.maritime_voyage_id === "" || item.trip_type_id === "";
          }
        });
        if (!invalidTable) {
          addBKLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rbkeditabletable"),
            type: "error",
          });
        }
      } else {
        addBKLine();
      }
    };

    const addBKLine = () => {
      store.commit("setBooking", {
        id: bookingTableData.value.length + 1,
        maritime_voyage_id: "",
        trip_type_id: "",
        booking_number: "",
        booking_date: "",
        reference: "",
      });
    };

    const removeBKItem = (index) => {
      store.commit("removeBooking", index);
    };

    const createTSElement = () => {
      if (transshipmentTableData.value.length > 0) {
        let invalidTable = false;
        transshipmentTableData.value.forEach((item, index) => {
          if (index === transshipmentTableData.value.length - 1) {
            invalidTable =
              item.maritime_voyage_id === "" ||
              item.booking_id === "" ||
              item.remarks === "";
          }
        });
        if (!invalidTable) {
          addTSLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rtseditabletable"),
            type: "error",
          });
        }
      } else {
        addTSLine();
      }
    };

    const addTSLine = () => {
      store.commit("setTransshipment", {
        booking_id: "",
        maritime_voyage_id: "",
        remarks: "",
      });
    };

    const removeTSItem = (index) => {
      store.commit("removeTransshipment", index);
    };

    const createBRElement = () => {
      if (routeTableData.value.length > 0) {
        let invalidTable = false;
        routeTableData.value.forEach((item, index) => {
          if (index === routeTableData.value.length - 1) {
            invalidTable =
              item.route_type_id === "" ||
              item.booking_id === "" ||
              item.order === "";
          }
        });
        if (!invalidTable) {
          addBRLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rbreditabletable"),
            type: "error",
          });
        }
      } else {
        addBRLine();
      }
    };

    const addBRLine = () => {
      store.commit("setBookingRoute", {
        booking_id: "",
        route_type_id: "",
        location_id: "",
        order: "",
        remarks: "",
      });
    };

    const removeBRItem = (index) => {
      store.commit("removeBookingRoute", index);
    };

    const createPElement = () => {
      if (participantTableData.value.length > 0) {
        let invalidTable = false;
        participantTableData.value.forEach((item, index) => {
          if (index === participantTableData.value.length - 1) {
            invalidTable =
              item.participant_type_id === "" || item.booking_id === "";
          }
        });
        if (!invalidTable) {
          addPLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rpeditabletable"),
            type: "error",
          });
        }
      } else {
        addPLine();
      }
    };

    const addPLine = () => {
      store.commit("setParticipant", {
        booking_id: "",
        entity_id: "",
        participant_type_id: "",
        entity_data: "",
      });
    };

    const removePItem = (index) => {
      store.commit("removeParticipant", index);
    };

    const createBLElement = () => {
      if (billOfLandingTableData.value.length > 0) {
        let invalidTable = false;
        billOfLandingTableData.value.forEach((item, index) => {
          if (index === billOfLandingTableData.value.length - 1) {
            invalidTable = item.booking_id === "";
          }
        });
        if (!invalidTable) {
          addBLLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rbleditabletable"),
            type: "error",
          });
        }
      } else {
        addBLLine();
      }
    };

    const addBLLine = () => {
      store.commit("setBillOfLanding", {
        booking_id: "",
        bl_date: "",
      });
    };

    const removeBLItem = (index) => {
      store.commit("removeBillOfLanding", index);
    };

    const createSLElement = () => {
      if (shiploadTableData.value.length > 0) {
        let invalidTable = false;
        shiploadTableData.value.forEach((item, index) => {
          if (index === shiploadTableData.value.length - 1) {
            invalidTable = item.booking_id === "";
          }
        });
        if (!invalidTable) {
          addSLLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rsleditabletable"),
            type: "error",
          });
        }
      } else {
        addSLLine();
      }
    };

    const addSLLine = () => {
      store.commit("setShipload", {
        id: shiploadTableData.value.length + 1,
        booking_id: "",
        bl_id: "",
        container: "",
        registered_container: "",
        goods: "",
        remarks: "",
        imo: "",
        registered_equipments: "",
      });
    };

    const removeSLItem = (index) => {
      store.commit("removeShipload", index);
    };

    const createGFElement = () => {
      if (goodTableData.value.length > 0) {
        let invalidTable = false;
        goodTableData.value.forEach((item, index) => {
          if (index === goodTableData.value.length - 1) {
            invalidTable =
              item.booking_id === "" ||
              item.shipload_id === "" ||
              item.measure_type_id === "" ||
              item.measure_unit_id === "" ||
              item.measure === "";
          }
        });
        if (!invalidTable) {
          addGFLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rgfeditabletable"),
            type: "error",
          });
        }
      } else {
        addGFLine();
      }
    };

    const addGFLine = () => {
      store.commit("setGood", {
        shipload_id: "",
        measure_type_id: "",
        measure_unit_id: "",
        measure: "",
        remarks: "",
      });
    };

    const removeGFItem = (index) => {
      store.commit("removeGood", index);
    };

    const onSubmit = (value) => {
      if (
        !bookingTableData.value.length ||
        !transshipmentTableData.value.length ||
        !routeTableData.value.length ||
        !participantTableData.value ||
        !billOfLandingTableData.value
      ) {
        ElNotification({
          title: "Error",
          message: t("rbooking"),
          type: "error",
        });
        return;
      }
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
    };

    return {
      submitButton,
      bookingTableData,
      transshipmentTableData,
      routeTableData,
      participantTableData,
      billOfLandingTableData,
      shiploadTableData,
      goodTableData,
      createBKElement,
      removeBKItem,
      createTSElement,
      removeTSItem,
      createBRElement,
      removeBRItem,
      createPElement,
      removePItem,
      createBLElement,
      removeBLItem,
      createSLElement,
      removeSLItem,
      createGFElement,
      removeGFItem,
      onSubmit,
    };
  },
});
