
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";

export default {
  name: "BookingsTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const loading = ref(false);
    const voyages = ref({
      loading: false,
      options: [],
      list: [],
    });
    const tripType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "id", prop: "id" },
      {
        label: "timaritimetripslist",
        prop: "maritime_voyage_id",
      },
      {
        label: "itripType",
        prop: "trip_type_id",
      },
      { label: "ibookingNumber", prop: "booking_number" },
      { label: "ibookingDate", prop: "booking_date" },
      { label: "ibookingref", prop: "reference" },
    ]);
    const headerTable = ref(headers.value);

    const getVoyage = () => {
      ApiService.get("/api/maritimevoyages").then(({ data }) => {
        voyages.value.list = data;
        voyages.value.options = data;
      });
    };
    const selectVoyage = (query) => {
      if (query !== "") {
        voyages.value.loading = true;
        setTimeout(() => {
          voyages.value.loading = false;
          voyages.value.options = voyages.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        voyages.value.options = [];
      }
    };

    const getTripType = () => {
      ApiService.get("/api/resource/maritimeTripType").then(({ data }) => {
        tripType.value.list = data;
        tripType.value.options = data;
      });
    };
    const selectTripType = (query) => {
      if (query !== "") {
        tripType.value.loading = true;
        setTimeout(() => {
          tripType.value.loading = false;
          tripType.value.options = tripType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        tripType.value.options = [];
      }
    };

    onMounted(() => {
      getVoyage();
      getTripType();
    });

    return {
      loading,
      voyages,
      tripType,
      headers,
      headerTable,
      selectVoyage,
      selectTripType,
    };
  },
};
