
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "BillsOfLadingTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();
    const loading = ref(false);
    const bookingTableData = computed(() => store.getters.Bookings);
    const headers = ref([
      { label: "tibookings", prop: "booking_id" },
      { label: "idate", prop: "bl_date" },
    ]);
    const headerTable = ref(headers.value);

    return {
      loading,
      bookingTableData,
      headers,
      headerTable,
    };
  },
};
