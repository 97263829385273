import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = { class: "my-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaritimeFileTabs = _resolveComponent("MaritimeFileTabs")!
  const _component_OverviewTab = _resolveComponent("OverviewTab")!
  const _component_Booking = _resolveComponent("Booking")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MaritimeFileTabs, { onChangeTab: _ctx.selectedTab }, null, 8, ["onChangeTab"]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_OverviewTab, null, null, 512), [
        [_vShow, _ctx.tab === 'overview']
      ]),
      _withDirectives(_createVNode(_component_Booking, null, null, 512), [
        [_vShow, _ctx.tab === 'bookings']
      ])
    ])
  ]))
}