
import { computed, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "ShiploadTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();
    const loading = ref(false);
    const bookingTableData = computed(() => store.getters.Bookings);
    const billOfLandingTableData = computed(() => store.getters.BillsOfLanding);
    const containers = ref({
      loading: false,
      options: [],
      list: [],
    });
    const rcontainers = ref({
      loading: false,
      options: [],
      list: [],
    });
    const equipments = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "tibookings", prop: "booking_id" },
      { label: "iblid", prop: "bl_id" },
      { label: "icontainer", prop: "container" },
      { label: "iregistercontainer", prop: "registered_container" },
      { label: "igoods", prop: "goods" },
      { label: "iremarks", prop: "remarks" },
      { label: "iimo", prop: "imo" },
    ]);
    const headerTable = ref(headers.value);

    const getContainers = () => {
      ApiService.get("/api/resource/equipment").then(({ data }) => {
        containers.value.list = data;
        containers.value.options = data;
      });
    };
    const selectContainer = (query) => {
      if (query !== "") {
        containers.value.loading = true;
        setTimeout(() => {
          containers.value.loading = false;
          containers.value.options = containers.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        containers.value.options = [];
      }
    };

    const getRContainers = () => {
      ApiService.get("/api/registered-equipment").then(({ data }) => {
        rcontainers.value.list = data;
        rcontainers.value.options = data;
      });
    };
    const selectRContainers = (query) => {
      if (query !== "") {
        rcontainers.value.loading = true;
        setTimeout(() => {
          rcontainers.value.loading = false;
          rcontainers.value.options = rcontainers.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        rcontainers.value.options = [];
      }
    };

    onMounted(() => {
      getContainers();
      getRContainers();
    });

    return {
      loading,
      bookingTableData,
      billOfLandingTableData,
      containers,
      rcontainers,
      equipments,
      headers,
      headerTable,
      selectContainer,
      selectRContainers,
    };
  },
};
