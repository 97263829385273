<template>
  <div class="tabs-container">
    <div v-if="maritimeFile" class="d-flex flex-wrap flex-sm-nowrap mb-3">
      <div class="flex-grow-1">
        <div class="d-flex flex-wrap flex-stack">
          <div class="d-flex flex-column flex-grow-1 pe-8">
            <div class="d-flex flex-wrap">
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span class="svg-icon svg-icon-success me-2"> </span>
                  <div class="fs-2 fw-bolder text-white">
                    {{ maritimeFile.code ? maritimeFile.code : "" }}
                  </div>
                </div>
                <div class="fw-bold fs-6 text-gray-400">
                  {{ $t("timaritimefile") }}
                </div>
              </div>
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span class="svg-icon svg-icon-success me-2"> </span>
                  <div class="fs-2 fw-bolder text-white">
                    {{ maritimeFile.office ? maritimeFile.office.name : "" }}
                  </div>
                </div>
                <div class="fw-bold fs-6 text-gray-400">
                  {{ $t("ioffices") }}
                </div>
              </div>
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span
                    class="svg-icon svg-icon-3 svg-icon-success me-2"
                  ></span>
                  <div class="fs-2 fw-bolder text-white">
                    {{ maritimeFile.port ? maritimeFile.port.name : "" }}
                  </div>
                </div>
                <div class="fw-bold fs-6 text-gray-400">
                  {{ $t("iport") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link active"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('overview')"
          >{{ $t("maritimeFileOverview") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          v-if="maritimeFileId"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('bookings')"
          >{{ $t("bookings") }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "MaritimeFileTabs",
  emits: ["changeTab"],

  setup(props, { emit }) {
    const store = useStore();
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const handleTabClick = (tab) => {
      emit("changeTab", tab);
    };

    return {
      props,
      maritimeFileId,
      maritimeFile,
      handleTabClick,
    };
  },
});
</script>
