
import { computed, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "GoodsFeaturesTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();
    const loading = ref(false);
    const shiploadTableData = computed(() => store.getters.Shiploads);
    const measureType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const measureUnit = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "iidshipload", prop: "shipload_id" },
      { label: "measures_type", prop: "measure_type_id" },
      { label: "measure_unit_type", prop: "measure_unit_id" },
      { label: "imeasure", prop: "measure" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const getMeasureType = () => {
      ApiService.get("/api/resource/measureType").then(({ data }) => {
        measureType.value.list = data;
        measureType.value.options = data;
      });
    };
    const selectMeasureType = (query) => {
      if (query !== "") {
        measureType.value.loading = true;
        setTimeout(() => {
          measureType.value.loading = false;
          measureType.value.options = measureType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        measureType.value.options = [];
      }
    };

    const getMeasureUnit = () => {
      ApiService.get("/api/measure-units").then(({ data }) => {
        measureUnit.value.list = data.measureUnit;
        measureUnit.value.options = data.measureUnit;
      });
    };
    const selectMeasureUnit = (query) => {
      if (query !== "") {
        measureUnit.value.loading = true;
        setTimeout(() => {
          measureUnit.value.loading = false;
          measureUnit.value.options = measureUnit.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        measureUnit.value.options = [];
      }
    };

    onMounted(() => {
      getMeasureType();
      getMeasureUnit();
    });

    return {
      loading,
      shiploadTableData,
      measureType,
      measureUnit,
      headers,
      headerTable,
      selectMeasureType,
      selectMeasureUnit,
    };
  },
};
